import * as React from 'react';
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import styled from '@emotion/styled';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper';
import HeroImageA from '../../components/MainHero';
import CenteredButton from '../../components/centeredButton';

const FlexTwo = styled.div`
  display: grid;


  @media (min-width: 1000px) {
    grid-template-columns: 2fr 1fr;
  }
`;

const FlexTwoLeft = styled.div`

`;
const FlexTwoRight = styled.div`
`;

const CommercialStrataServices = () => (
    <Layout>
		<Seo title="Commercial and Strata Painting and Reno's"
         description='Commercial and strata painting and renovation services' />

      {/* Hero Image is found in /components/MainHero.js */}
      <HeroImageA />

		<SiteWidthWrapper>

			<FlexTwo>
				<FlexTwoLeft>
					<h1>COMMERCIAL / STRATA</h1>
					<p>At Top1 painting We find the most effective and productive means to completing your commercial projects not just saving you time, money but no headaches. Our team has worked on commercial projects such as Rona and large gym facilities, no job is too big or small.We are professional painters who work together to successfully finish commercial projects in the most efficient and cost-effective way. We at Top1 Painting understand how important your business and its customers are to you, Your business’ appeal and charm is greatly enhanced by the way your space is finished as well as its overall look. The value of your business space and property goes up when you have surfaces with quality paint. When it comes to preparing for the job, determining the size of the job, figuring downtime, as well as calculating the time and cost of your project, we take care of it all. To make sure you are completely satisfied with our work, we use only the highest quality paint and material.</p>
					<p>At Top1 Painting we offer a wide variety of strata service for townhouse, or condominium. Painting a multi-unit complex you need unique set of skills by the painting contractor. Our painters understand that when painting for a strata project, the procedures are not the same as residential household painting. At Top1 Painting we work with you to complete your strata, condo &amp; apartment building painting to bringing new life to the property and overused exterior and interior surfaces of your unit. At Top1 Painting we offer a minimum of disruption and a job completed to the highest standards. Our goal is to provide the highest quality service at the most competitive prices and to maintain total honesty, fairness, and transparency in the relationships we develop with our clients, especially when we are working on a strata, condo & apartment building project.</p>
					<p>We want to show you how easy your project can be and we want your complete satisfaction. Anything less is simply not acceptable to us. Just contact us for a free estimate and you’ll see right away how positively different a company we really are!</p>
					<CenteredButton>Call Now</CenteredButton>
				</FlexTwoLeft>
				<FlexTwoRight>
					<h2>Commercial &amp; Strata Painting Services Include:</h2>
					<ul>
						<li>High Rises</li>
						<li>Mid Rises</li>
						<li>Town homes</li>
						<li>Property Management Company jobs</li>
						<li>Commercial buildings</li>
					</ul>
				</FlexTwoRight>
			</FlexTwo>
		</SiteWidthWrapper>
	</Layout>
);

export default CommercialStrataServices;
